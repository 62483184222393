import React from "react";
import "./App.css";
import { GoodTimes } from "./GoodTimes";

/**
Goals:
- Current Time
    - this is either a digital clock readout, or... just the hands on the clos


- Next "good time"
    - Probably need a real date library for this
- Clock View
    - Good as is, just make it tick instead of smooth cuz of the bug
    - Or just leave the bug? It's kinda cute
- Good Time selector
    - 3 presets (360, 180, 90)
    - Free form entry (must be a number?)
    - Color selection?


Arch:

Top level state:
- Current Time
- Good Time config (degrees, color)
- `theTimesWithAngles` const (don't worry about it)

*/

function App() {
  return <GoodTimes />;
}

export default App;
