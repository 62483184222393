import { Property } from "csstype";
import { transparentPurple, transparentGreen } from "./colors";

export interface GoodTimeConfig {
  angle: number;
  color: Property.Background<string | number>;
}

interface GoodTimeSelectorProps {
  config: GoodTimeConfig;
  setConfig: (target: GoodTimeConfig) => void;
}

export function GoodTimeSelector({
  config,
  setConfig,
}: GoodTimeSelectorProps): React.ReactElement {
  const defaultAngles = [360, 180, 90];
  const isCustomAngleSelected = !defaultAngles.includes(config.angle);
  return (
    <div className="grid grid-cols-2 my-4 w-full items-center">
      <SelectorButton
        selected={config.angle === 360}
        onClick={(e) => setConfig({ angle: 360, color: transparentPurple })}
      >
        360°
      </SelectorButton>
      <SelectorButton
        selected={config.angle === 180}
        onClick={(e) => setConfig({ angle: 180, color: transparentPurple })}
      >
        180°
      </SelectorButton>
      <SelectorButton
        selected={config.angle === 90}
        onClick={(e) => setConfig({ angle: 90, color: transparentPurple })}
      >
        90°
      </SelectorButton>
      <input
        type={"number"}
        inputMode="numeric"
        min={0}
        max={360}
        size={1}
        className="border border-black rounded font-bold flex-1 px-10 py-3 m-2 placeholder-stone-500 m-w-0"
        onChange={(e) =>
          setConfig({
            angle: parseInt(e.target.value),
            color: transparentGreen,
          })
        }
        placeholder={isCustomAngleSelected ? undefined : "Custom..."}
        value={isCustomAngleSelected ? config.angle : ""}
      />
    </div>
  );
}

interface SelectorButtonProps {
  onClick: React.MouseEventHandler;
  children: React.ReactNode;
  selected?: boolean;
}

function SelectorButton({ onClick, selected, children }: SelectorButtonProps) {
  const borderColor = selected
    ? "border-purple-800 bg-purple-800 text-white"
    : "border-black";
  return (
    <button
      className={`font-bold py-3 px-10 m-2 border ${borderColor} hover:border-purple-200 hover:bg-purple-200 hover:text-black rounded flex-1`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
