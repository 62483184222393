import React, { useEffect, useState } from "react";
import {
  calculateGoodTimes,
  findNextGoodTime,
  now,
  theTimesWithAngles,
  Time,
  TimeAndAngles,
  timeUntil,
} from "./clockModel";
import { GoodTimeConfig, GoodTimeSelector } from "./GoodTimeSelector";
import { Clock } from "./Clock";
import { transparentPurple } from "./colors";

const allTimes = theTimesWithAngles();

export function GoodTimes(): React.ReactElement {
  const [currentTime, setCurrentTime] = React.useState(now());
  React.useEffect(() => {
    const interval = setInterval(() => setCurrentTime(now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [goodTimeConfig, setGoodTimeConfig] = React.useState<GoodTimeConfig>({
    angle: 360,
    color: transparentPurple,
  });

  const [goodTimes, setGoodTimes] = useState<TimeAndAngles[]>(
    calculateGoodTimes(allTimes, goodTimeConfig.angle),
  );
  useEffect(() => {
    setGoodTimes(calculateGoodTimes(allTimes, goodTimeConfig.angle));
  }, [goodTimeConfig]);

  const nextGoodTime = findNextGoodTime(goodTimes, currentTime);

  return (
    <div className={"flex w-full justify-center my-4"}>
      <div>
        <Clock
          goodTimes={goodTimes}
          selectedTime={nextGoodTime}
          goodTimeConfig={goodTimeConfig}
          time={currentTime}
        />

        <NextGoodTime selectedTime={nextGoodTime} time={currentTime} />
        <GoodTimeSelector
          config={goodTimeConfig}
          setConfig={setGoodTimeConfig}
        />
      </div>
    </div>
  );
}

interface NextGoodTimeProps {
  selectedTime?: TimeAndAngles;
  time: Time;
}

function NextGoodTime({ selectedTime, time }: NextGoodTimeProps) {
  return (
    <div className="w-full items-center text-center my-8">
      <div>{"Current Time: " + digitalClockReadout(time)}</div>
      <div>
        {selectedTime?.time ? (
          <>
            {"Next Good Time: "}
            <strong>{digitalClockReadout(selectedTime.time)}</strong>
          </>
        ) : (
          "Can't find next good time! :("
        )}
      </div>
      <div>
        <em>{timeUntil(time, selectedTime?.time)}</em>
      </div>
    </div>
  );
}

function digitalClockReadout(time: Time): string {
  return `${time.hour || 12}:${String(time.minute).padStart(2, "0")}:${String(time.second).padStart(2, "0")}`;
}
