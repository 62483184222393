import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime, {
  thresholds: [
    { l: "s", r: 1 },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 29, d: "day" },
    { l: "M", r: 1 },
    { l: "MM", r: 11, d: "month" },
    { l: "y", r: 1 },
    { l: "yy", d: "year" },
  ],
});

export interface Time {
  hour: number;
  minute: number;
  second: number;
}

export interface TimeAndAngles {
  time: Time;
  hourAngle: number;
  minuteAngle: number;
  secondAngle: number;
  angleBetween: number;
}

export function calculateAngles(time: Time): TimeAndAngles {
  const secondAngle = calculateSecondAngle(time);
  const minuteAngle = calcMinuteAngle(time);
  const hourAngle = calcHourAngle(time);

  const angleBetween = 360 - Math.abs(hourAngle - minuteAngle);

  return { time, minuteAngle, hourAngle, secondAngle, angleBetween };
}

export function now(): Time {
  const currentDate = new Date();
  const hour = currentDate.getHours();

  return {
    hour: hour > 12 ? hour - 12 : hour,
    minute: currentDate.getMinutes(),
    second: currentDate.getSeconds(),
  };
}

export function angleBetweenHands(time: Time): number {
  const hourAngle = calcHourAngle(time);

  const minuteAngle = calcMinuteAngle(time);

  return 360 - Math.abs(hourAngle - minuteAngle);
}

export function calcMinuteAngle(time: Time) {
  return (360 / 60) * time.minute + (360 / 60 / 60) * time.second;
}

export function calculateSecondAngle(time: Time): number {
  return (360 / 60) * time.second;
}

export function calcHourAngle(time: Time) {
  return (
    (360 / 12) * time.hour +
    (360 / 12 / 60) * time.minute +
    (360 / 12 / 60 / 60) * time.second
  );
}

export function near180(angle: number): boolean {
  return nearTarget(180, angle);
}

export function near360(angle: number): boolean {
  return nearTarget(360, angle);
}

export function nearTarget(target: number, angle: number): boolean {
  const oneWay = 0.1 > Math.abs(target - angle);
  const theOther = 0.1 > Math.abs(360 - target - angle);
  return oneWay || theOther;
}

export function theTimes() {
  // A magnificently innefficient way of making times
  const hours = Array.from({ length: 12 }, (_, index) => index);
  const mins = Array.from({ length: 60 }, (_, index) => index);
  const secs = Array.from({ length: 60 }, (_, index) => index);
  return hours.flatMap((hour) => {
    return mins.flatMap((minute) => {
      return secs.flatMap((second) => ({ hour, minute, second }));
    });
  });
}

export function theTimesWithAngles() {
  return theTimes().map(calculateAngles);
}

export function timesMatch(one: Time, other: Time | undefined): boolean {
  return (
    other !== undefined &&
    one.hour === other.hour &&
    one.minute === other.minute &&
    one.second === other.second
  );
}

export function calculateGoodTimes(
  inputTimes: TimeAndAngles[],
  target: number,
): TimeAndAngles[] {
  return inputTimes.filter((t) => nearTarget(target, t.angleBetween));
}

export function isAfter(time: Time, timeToCompare: Time): boolean {
  return toDayJs(time).isAfter(toDayJs(timeToCompare));
}

export function toDayJs(time: Time): Dayjs {
  return dayjs().hour(time.hour).minute(time.minute).second(time.second);
}

export function timeUntil(currentTime: Time, selectedTime?: Time): string {
  if (!selectedTime) {
    return "";
  }
  if (isAfter(currentTime, selectedTime)) {
    selectedTime = { ...selectedTime, hour: selectedTime.hour + 12 };
  }
  return toDayJs(selectedTime).from(toDayJs(currentTime));
}

export function findNextGoodTime(
  goodTimes: TimeAndAngles[],
  currentTime: Time,
): TimeAndAngles | undefined {
  if (!goodTimes.length) {
    return undefined;
  }
  const timeToCheck =
    currentTime.hour >= 12
      ? { ...currentTime, hour: currentTime.hour - 12 }
      : currentTime;

  return [...goodTimes, goodTimes[0]].find((goodTime) =>
    isAfter(goodTime.time, timeToCheck),
  );
}
