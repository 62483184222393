import { Property } from "csstype";
import {
  calcHourAngle,
  calcMinuteAngle,
  calculateAngles,
  Time,
  TimeAndAngles,
  timesMatch,
} from "./clockModel";
import { GoodTimeConfig } from "./GoodTimeSelector";

interface ClockProps {
  goodTimes: TimeAndAngles[];
  selectedTime?: TimeAndAngles;
  goodTimeConfig: GoodTimeConfig;
  time: Time;
}

export function Clock({
  goodTimes,
  selectedTime,
  goodTimeConfig,
  time,
}: ClockProps): React.ReactElement {
  return (
    <div
      id="clockFace"
      className={"mx-auto"}
      style={{
        position: "relative",
        borderRadius: "50%",
        height: "50vh",
        width: "50vh",
        boxSizing: "border-box",
        border: "1px solid black",
      }}
    >
      <Ticks />
      <HighlightedHands
        goodTimes={goodTimes}
        selectedTime={selectedTime}
        goodTimeConfig={goodTimeConfig}
      />
      <CurrentHands time={time} />
    </div>
  );
}

function CurrentHands(props: { time: Time }): React.ReactElement {
  const { minuteAngle, hourAngle, secondAngle } = calculateAngles(props.time);
  const color = "black";
  const isSelected = false;
  return (
    <>
      <Hand angle={secondAngle} color={"red"} length={40} width={1} />
      <Hand
        angle={minuteAngle}
        color={color}
        length={40}
        width={3}
        isSelected={isSelected}
      />
      <Hand
        angle={hourAngle}
        color={color}
        length={25}
        width={5}
        isSelected={isSelected}
      />
    </>
  );
}

interface HighlightedHandsProps {
  goodTimes: TimeAndAngles[];
  selectedTime: TimeAndAngles | undefined;
  goodTimeConfig: GoodTimeConfig;
}

function HighlightedHands(props: HighlightedHandsProps): React.ReactElement {
  return (
    <>
      {props.goodTimes.map((time) => {
        const { minuteAngle, hourAngle } = time;
        const color = props.goodTimeConfig.color;
        const isSelected = timesMatch(time.time, props.selectedTime?.time);
        return (
          <>
            <Hand
              angle={minuteAngle}
              color={color}
              length={40}
              width={2}
              isSelected={isSelected}
            />
            <Hand
              angle={hourAngle}
              color={color}
              length={30}
              width={3}
              isSelected={isSelected}
            />
          </>
        );
      })}
    </>
  );
}

interface HandProps {
  angle: number;
  length: number;
  oppositeLength?: number;
  width?: number;
  color: Property.Background<number | string>;
  onMouseOver?: React.MouseEventHandler;
  isSelected?: boolean;
}

function Hand({
  angle,
  length,
  oppositeLength = 5,
  width = 3,
  color,
  onMouseOver,
  isSelected,
}: HandProps): React.ReactElement {
  return (
    <div
      style={{
        transition: "transform 1s linear",
        transform: `rotate(${angle}deg)`,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "50%",
        right: "50%",
      }}
    >
      <div
        onMouseOver={onMouseOver}
        style={{
          position: "absolute",
          top: `${50 - length}%`,
          bottom: `${50 - oppositeLength}%`,
          width: isSelected ? width * 4 : width,
          transition: "width 0.5s",
          background: color,
          transform: "translateX(-50%)",
        }}
      />
    </div>
  );
}

function Ticks(): React.ReactElement {
  return (
    <>
      {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
        <Tick
          angle={calcHourAngle({ hour, minute: 0, second: 0 })}
          length={5}
          width={2}
          color="darkGrey"
          num={hour.toString()}
          key={hour}
        />
      ))}
      {Array.from({ length: 60 }, (_, i) => i + 1).map((minute) => (
        <Tick
          angle={calcMinuteAngle({ hour: 0, minute, second: 0 })}
          length={2}
          width={1}
          color="darkGrey"
          key={minute}
        />
      ))}
    </>
  );
}

function Tick({
  angle,
  length,
  width,
  color,
  num,
}: {
  angle: number;
  length: number;
  width: number;
  color: string;
  num?: string;
}): React.ReactElement {
  return (
    <div
      style={{
        transform: `rotate(${angle}deg)`,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "50%",
        right: "50%",
      }}
    >
      <div
        className="text-xl font-serif"
        style={{
          transform: `translateX(-50%) rotate(-${angle}deg)`,
          position: "absolute",
          top: `${length + 1}%`,
        }}
      >
        {num}
      </div>
      <div
        style={{
          position: "absolute",
          top: `${0}%`,
          bottom: `${100 - length}%`,
          width: width,
          background: color,
          transform: "translateX(-50%)",
        }}
      />
    </div>
  );
}
